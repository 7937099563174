import { useStorage } from "@vueuse/core";

export const useChatStore = defineStore("chat", () => {
  // Save messages in local storage
  const messages = useStorage("history", <Message[]>[], undefined, {
    serializer: {
      read: (v: any) => {
        return v ? JSON.parse(v) : null;
      },
      write: (v: any) => {
        // Only save text messages to chat history
        return JSON.stringify(
          v.filter((message: Message) => message.type === "text"),
        );
      },
    },
  });

  return {
    messages,
  };
});
