import { useStorage } from "@vueuse/core";

export type ContextType = "apiMessage" | "userMessage";

export type Context = {
  message: string;
  type: ContextType;
};

export const useContextStore = defineStore("context", () => {
  const context = useStorage("context", <Context[]>[]);

  function addContext(message: string, type: ContextType) {
    context.value.push({
      message,
      type,
    });
  }

  function clearContext() {
    context.value = [];
  }

  return {
    context,
    addContext,
    clearContext,
  };
});
