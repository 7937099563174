import { v4 as uuidv4 } from "uuid";

export type Role = "assistant" | "user" | "system";

export type MessageType = "text" | "category-select";

export type MessageData = MessageDataText | MessageDataCategories;
export type MessageDataText = { content: string };
export type MessageDataCategories = object;

export type Message = {
  id: string;
  type: MessageType;
  role: Role;
  data: MessageData;
  time: string;
  blocking: boolean;
};

const bus = useEventBus<string>("main");

export const useChat = (name: string = "main") => {
  const chatStore = useChatStore();

  const messages =
    name === "main" ? ref(chatStore.messages) : ref(<Message[]>[]);

  const unlockedMessages = computed(() => {
    let blocking = false;
    return messages.value.filter((message: Message) => {
      // If a message is currently blocking, remove this item from visible list
      if (blocking) return false;
      // If this message is blocking, remove next items from visible list
      if (message.blocking) blocking = true;
      // Include message in visible list
      return true;
    });
  });

  const totalMessages = computed(() => {
    return messages.value.length;
  });

  const hasLockedMessages = computed<boolean>(() => {
    return (
      messages.value.find((message: Message) => message.blocking) !== undefined
    );
  });

  function addMessage(message: Partial<Message>): string {
    const id = uuidv4();
    const time = new Date().toLocaleTimeString([], {
      hour: "2-digit",
      minute: "2-digit",
    });
    const defaultMessage: Message = {
      id,
      type: "text",
      role: "assistant",
      data: {},
      blocking: true,
      time,
    };

    messages.value.push({ ...defaultMessage, ...message });
    // Fire updated event
    bus.emit("chat:updated", name);
    // Return id
    return id;
  }

  function addTextMessage(content: string, role: Role): string {
    return addMessage({
      data: {
        content,
      },
      role,
    });
  }

  function removeLastMessage() {
    messages.value.pop();
  }

  function removeLastMessages(amount = 1) {
    messages.value = messages.value.slice(0, -amount);
  }

  function clearMessages() {
    messages.value = [];
  }

  return {
    messages,
    unlockedMessages,
    totalMessages,
    hasLockedMessages,
    addMessage,
    addTextMessage,
    removeLastMessage,
    removeLastMessages,
    clearMessages,
  };
};
